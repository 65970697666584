/* COLORS */
div.c1,
button.c1,
nav.c1,
.btn.c1,
span.c1 {
  background-color: #00658d !important;
  color: white;
}
.btn.c1:hover {
  background-color: #0061aa !important;
}
div.c2,
button.c2,
nav.c2,
.btn.c2,
span.c2 {
  background-color: #8f44dc !important;
  color: white;
}
div.b2 {
  border-color: #8f44dc !important;
}
.btn.c2:hover {
  background-color: #7228bf !important;
}
div.c3,
button.c3,
nav.c3,
.btn.c3,
span.c3 {
  background-color: #001a25 !important;
  color: white;
}
.btn.c3:hover {
  background-color: #261d54 !important;
}

.page-title.c2 {
  background: #183153 !important;
}
.page-title .btn-primary.c3 {
  background: transparent !important;
  border: 2px solid white !important;
  border-radius: 50px;
}
.page-title .btn-primary.c3:hover {
  background: #4f6c7c !important;
  border-color: #4f6c7c !important;
  color: white !important;
}

.outline.btn.btn-primary.c3 {
  background: transparent !important;
  border: 2px solid white !important;
  border-radius: 50px;
}

.outline.btn.btn-primary.c3:hover {
  background: #95aac9 !important;
  border-color: #95aac9 !important;
  color: white !important;
}
button.btn-outline-primary {
  background-color: transparent !important;
  border: 1px solid;
  color: #00658d;
  border-color: #00658d;
}
button.btn-outline-primary:hover {
  color: #fff;
  background-color: #00658d !important;
  border-color: #00658d !important;
}

/* GENERAL */
#sidebar .nav-active {
  background: transparent linear-gradient(270deg, #0080b3 0%, #00658d 100%) 0%
    0% no-repeat padding-box;
  border-left: solid 2px #00658d;
}
.sidebarIcon {
  color: #a43ebd;
}
.card-one {
  background: #00658d 0% 0% no-repeat padding-box;
}
.card-two {
  background: #00658d 0% 0% no-repeat padding-box;
}
.card-three {
  background: #00415a 0% 0% no-repeat padding-box;
}
.card-four {
  background: #001a25 0% 0% no-repeat padding-box;
}
/* LOGIN */
.bg-cover {
  background: linear-gradient(#00658d, #00658d) 0% 0% / cover;
}
#form-login button {
  background: #00658d;
  border: 0;
  transition: background 0.5s;
}
#form-login button:hover {
  background: rgb(81 8 86);
}
/*LOADING*/
#loading-border {
  border-color: #00658d;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
    color: #00658d;
  }
  50% {
    transform: rotate(180deg);
    color: #00658d;
  }
  100% {
    transform: rotate(359deg);
    color: #00658d;
  }
}
#loading-icon span {
  background-color: #00658d;
}
#loading p:nth-child(2) {
  color: #00658d;
}

/* EVENT CREATE */
#event-accept {
  background: #00658d;
}
.step-active {
  border-left-color: #00658d;
  color: #00658d;
}
.step-active .step-bullet {
  color: #00658d;
  border-color: #00658d;
}
.event-tags .event-tag {
  background-color: #00658d;
}
#create-event-2 .btn-blue-border {
  color: #00658d;
  border: 2px solid #00658d !important;
}
#event-accept .btn-white {
  color: #00658d;
}
.alert-primary {
  background-color: #00658d !important;
  border-color: #00658d !important;
}
